import React from "react"

import logo from "../images/logo.png"
import SectionHeader from "./section-header"

const Content = () => (
  <div style={{ padding: "1rem 1rem", textAlign: "center" }}>
    <SectionHeader
      title="Tasty Lockscreen Backgrounds"
    />
    <content
                       className="multiGrid"
    >
                       <div>
                       <img src={logo} alt="app logo" className="logoImage" />
                       </div>
      <div style={{ textAlign: "left" }}>
      • Gorgeous hand-picked food-themed wallpapers for iOS<br />
      • No subscription! And no annoying fullscreen pop-ups<br />
      • Hundreds of free wallpapers, organized into handy categories<br />
      <br />
      • Wallpapers optimized for your iPhone, from SE to Xs Max<br />
      • Dark mode wallpapers for iOS 13<br />
      • Categories for every taste<br />
      • Special limited edition categories for seasonal holidays<br />
      • Up to 12 featured wallpapers daily, check out "Today's Special" category to view them<br />
      • Preview wallpapers on the lock screen and home screen without leaving the app<br />
      • Download wallpapers with one tap!<br />

      </div>
    </content>
  </div>
)

export default Content
